import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form-submission"
export default class extends Controller {
  static targets = ["titleInput", "form"];

  connect() {
    // Optionally, you can do some initialization here
  }

  submitForm(event) {
    event.preventDefault();

    if (this.titleInputTarget.value.trim() !== "") {
      this.sendAjaxRequest();
    }
  }

  async sendAjaxRequest() {
    const formData = new FormData(this.formTarget);

    try {
      const response = await fetch(this.formTarget.action, {
        method: this.formTarget.method,
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
          Accept: "application/json",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Handle successful response
      console.log("Form successfully submitted");
      // Optionally, update the UI or show a success message
    } catch (error) {
      // Handle error
      console.error("Form submission error:", error);
    }
  }
}
