import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

// Connects to data-controller="diary-card"
export default class extends Controller {
  static targets = [
    "diaryCheckbox",
    "form",
    "card",
    "modal",
    "skillModal",
    "skillInput",
    "skillContainer",
    "newUrge",
    "newTechniqueContainer",
    "hiddenTechniqueValue",
    "hiddenSkillValues",
    "hiddenSkillInputFields",
    "newTechniqueValue",
    "newTechniqueField",
    "techniqueInput",
    "hiddenTitleInput",
    "existingTechniqueValue",
    "techniqueSubmit",
    "saveAction",
    "techniqueCreation",
  ];

  connect() {}

  formCheckbox(event) {
    if (this.formTarget.querySelectorAll(".diary-checkbox-inputs").length > 0) {
      this.uncheckCheckboxesNumbers();
    } else {
      this.uncheckCheckboxYesNo();
    }

    this.uncheckCheckbox(event);
    const checkboxLabel = event.currentTarget;
    const checkboxInput = checkboxLabel.querySelector(".diary-checkbox-inputs");
    if (checkboxInput) {
      const valueClass = `checked-blue-${checkboxInput.value}`;
      if (checkboxLabel.classList.contains("checked-blue")) {
        checkboxLabel.classList.remove("checked-blue", valueClass);
      } else {
        checkboxLabel.classList.add("checked-blue", valueClass);
      }
    } else {
      if (checkboxLabel.classList.contains("checked-blue")) {
        checkboxLabel.classList.remove("checked-blue");
      } else {
        checkboxLabel.classList.add("checked-blue");
      }
    }
  }

  uncheckCheckbox(event) {
    const checkboxLabels = this.formTarget.querySelectorAll("label");

    checkboxLabels.forEach((label) => {
      label.classList.remove("checked-blue");
      const classList = Array.from(label.classList);
      classList.forEach((className) => {
        if (className.startsWith("checked-blue-")) {
          label.classList.remove(className);
        }
      });
    });
  }

  uncheckCheckboxesNumbers() {
    const checkboxes = this.formTarget.querySelectorAll(
      ".diary-checkbox-inputs",
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  uncheckCheckboxYesNo() {
    const checkboxes = this.formTarget.querySelectorAll(
      ".diary-checkbox-inputs-yes-no",
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  openNewTechniqueModal() {
    this.saveActionTarget.dataset.action = "mouseup->diary-card#setValue";

    const enterKeyListener = (event) => {
      if (
        event.key === "Enter" &&
        this.hiddenTechniqueValueTarget.value !== ""
      ) {
        event.preventDefault();
        this.setValue();
        document.removeEventListener("keydown", enterKeyListener);
      }
    };

    document.addEventListener("keydown", enterKeyListener);
    this.modalTarget.classList.add("open");
    this.techniqueSubmitTarget.disabled = true;
  }

  // changing technique card title

  openChangeTechniqueModal(event) {
    const techniqueCard = event.currentTarget;

    const enterKeyListener = (secondEvent) => {
      if (
        secondEvent.key === "Enter" &&
        this.hiddenTechniqueValueTarget.value !== ""
      ) {
        secondEvent.preventDefault();
        this.changeTechniqueCard(techniqueCard, "coping_technique");
        document.removeEventListener("keydown", enterKeyListener);
      }
    };

    document.addEventListener("keydown", enterKeyListener);
    this.modalTarget.classList.add("open");
    this.saveActionTarget.dataset.action = "mouseup->diary-card#fakeEnterKey";
    this.techniqueSubmitTarget.disabled = true;
  }

  openNewUrgeModal() {
    this.saveActionTarget.dataset.action = "mouseup->diary-card#setUrgeValue";

    const enterKeyListener = (event) => {
      if (
        event.key === "Enter" &&
        this.hiddenTechniqueValueTarget.value !== ""
      ) {
        event.preventDefault();
        this.setUrgeValue();
        document.removeEventListener("keydown", enterKeyListener);
      }
    };

    document.addEventListener("keydown", enterKeyListener);
    this.modalTarget.classList.add("open");
    this.techniqueSubmitTarget.disabled = true;
  }

  openChangeUrgeModal(event) {
    const techniqueCard = event.currentTarget;

    const enterKeyListener = (secondEvent) => {
      if (
        secondEvent.key === "Enter" &&
        this.hiddenTechniqueValueTarget.value !== ""
      ) {
        secondEvent.preventDefault();
        this.changeTechniqueCard(techniqueCard, "negative_urge");
        document.removeEventListener("keydown", enterKeyListener);
      }
    };

    document.addEventListener("keydown", enterKeyListener);
    this.modalTarget.classList.add("open");
    this.saveActionTarget.dataset.action = "mouseup->diary-card#fakeEnterKey";
    this.techniqueSubmitTarget.disabled = true;
  }

  fakeEnterKey() {
    const enterKeyEvent = new KeyboardEvent("keydown", { key: "Enter" });

    // Replace 'yourTargetElement' with the actual target element that is listening for the 'Enter' key
    document.dispatchEvent(enterKeyEvent);
  }

  changeTechniqueCard(techniqueCard, type) {
    techniqueCard.innerHTML = `<input type='hidden' name='${type}[][title]' value='${this.hiddenTechniqueValueTarget.value}'><p>${this.hiddenTechniqueValueTarget.value}</p >`;
    this.closeNewTechniqueModal();
    this.clearTechniqueForm();
  }

  removeTechniqueFromDayCard(event) {
    const techniqueCard = event.currentTarget;
    techniqueCard.remove();
  }

  // other stuff

  closeNewTechniqueModal() {
    this.modalTarget.classList.remove("open");
    this.techniqueSubmitTarget.disabled = false;
    this.saveActionTarget.setAttribute("data-action", "");
  }

  changeValue() {
    const newValue = this.hiddenTechniqueValueTarget.value;

    if (newValue === "") {
      this.newTechniqueFieldTarget.classList.remove("open");
    } else {
      this.newTechniqueFieldTarget.classList.add("open");
    }

    this.newTechniqueValueTarget.innerHTML = `<p>${newValue}</p>`;
  }

  setValue() {
    const value = this.hiddenTechniqueValueTarget.value;
    if (value !== "") {
      this.createCopingTechnique(value);
    }
  }

  setExistingValue(event) {
    const existingValue = event.currentTarget.innerText;
    this.hiddenTechniqueValueTarget.value = existingValue;
    this.hiddenTechniqueValueTarget.focus();
  }

  createCopingTechnique(value) {
    this.createTechniqueHTML(value);
    this.closeNewTechniqueModal();
    this.clearTechniqueForm();
    const forms = document.querySelectorAll(
      '[name="coping_technique[][title]"]',
    );
    if (forms.length >= 3) {
      this.removeNewTechniqueOption();
    }
  }

  clearTechniqueForm() {
    this.hiddenTechniqueValueTarget.value = "";
  }

  createTechniqueHTML(value) {
    const newTechniqueContainer = this.newTechniqueContainerTarget;
    const newTechniqueHTML = `<div class="mt-3" data-controller="delete-html-card" data-delete-html-card-target="card">
    <div class="d-flex justify-content-between align-items-center border-beige-dark coping-title" data-action="click->diary-card#openChangeTechniqueModal" data-diary-card-target="allTechniques">
      <input type="hidden" name="coping_technique[][title]" value="${value}">
      <p>${value}</p>
      <img class="close-button" data-action="click->delete-html-card#delete" src="/assets/icons/close-cross.svg">    
    </div>
    <div class="diary-checkbox-title" style="border-radius: 0 !important;">
      <p>Dran gedacht?</p>
    </div>
    <div class="diary-checkbox-form" data-controller="diary-card" data-diary-card-target="form" style="border-radius: 0 !important;">
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-yes-no" type="checkbox" name="coping_technique[][thought_of]" data-diary-card-target="diaryCheckbox" value="1"><span class="checkmark">Ja</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-yes-no" type="checkbox" name="coping_technique[][thought_of]" data-diary-card-target="diaryCheckbox" value="0"><span class="checkmark">Nein</span></label></div>
    </div>

    <div class="diary-checkbox-title" style="border-radius: 0 !important;">
      <p>Ausprobiert</p>
    </div>
    <div class="diary-checkbox-form" data-controller="diary-card" data-diary-card-target="form">
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="coping_technique[][used]" data-diary-card-target="diaryCheckbox" value="0"><span class="checkmark">0</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="coping_technique[][used]" data-diary-card-target="diaryCheckbox" value="1"><span class="checkmark">1</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="coping_technique[][used]" data-diary-card-target="diaryCheckbox" value="2"><span class="checkmark">2</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="coping_technique[][used]" data-diary-card-target="diaryCheckbox" value="3"><span class="checkmark">3</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="coping_technique[][used]" data-diary-card-target="diaryCheckbox" value="4"><span class="checkmark">4</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="coping_technique[][used]" data-diary-card-target="diaryCheckbox" value="5"><span class="checkmark">5</span></label></div>
    </div>
    <div class="diary-checkbox-info">
      <p>Gar nicht</p>
      <p>Häufig</p>
    </div>
  </div>`;
    newTechniqueContainer.insertAdjacentHTML("beforeend", newTechniqueHTML);
  }

  // Negative Urges Methods

  createNegativeUrgeHTML(value) {
    const newTechniqueContainer = this.newTechniqueContainerTarget;
    const newTechniqueHTML = `<div class="mt-3" data-controller="delete-html-card" data-delete-html-card-target="card" data-diary-card-target="card">
    <div class="d-flex justify-content-between align-items-center border-beige-dark coping-title" data-action="click->diary-card#openChangeUrgeModal">
        <input type="hidden" name="negative_urge[][title]" value="${value}" data-action="click->diary-card#openChangeUrgeModal">
        <p>${value}</p>
        <img class="close-button" data-action="click->delete-html-card#delete" src="/assets/icons/close-cross.svg">    
    </div>
    <div class="diary-checkbox-title" style="border-radius: 0 !important;">
      <p>Drang</p>
    </div>
    <div class="diary-checkbox-form" data-controller="diary-card" data-diary-card-target="form">
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="negative_urge[][urge]" data-diary-card-target="diaryCheckbox" value="0"><span class="checkmark">0</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="negative_urge[][urge]" data-diary-card-target="diaryCheckbox" value="1"><span class="checkmark">1</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="negative_urge[][urge]" data-diary-card-target="diaryCheckbox" value="2"><span class="checkmark">2</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="negative_urge[][urge]" data-diary-card-target="diaryCheckbox" value="3"><span class="checkmark">3</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="negative_urge[][urge]" data-diary-card-target="diaryCheckbox" value="4"><span class="checkmark">4</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-inputs" type="checkbox" name="negative_urge[][urge]" data-diary-card-target="diaryCheckbox" value="5"><span class="checkmark">5</span></label></div>
    </div>
    <div class="diary-checkbox-info">
      <p>Gar nicht</p>
      <p>Häufig</p>
    </div>
    <div class="diary-checkbox-title mt-3"">
      <p>Handlung</p>
    </div>
    <div class="diary-checkbox-form" data-controller="diary-card" data-diary-card-target="form"">
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-yes-no" type="checkbox" name="negative_urge[][treatment]" data-diary-card-target="diaryCheckbox" value="1"><span class="checkmark">Ja</span></label></div>
      <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" class=""><input class="diary-card-checkbox-yes-no" type="checkbox" name="negative_urge[][treatment]" data-diary-card-target="diaryCheckbox" value="0"><span class="checkmark">Nein</span></label></div>
    </div>

  </div>`;
    newTechniqueContainer.insertAdjacentHTML("beforeend", newTechniqueHTML);
  }

  setUrgeValue() {
    const value = this.hiddenTechniqueValueTarget.value;
    this.createNegativeUrge(value);
  }

  setUrgeExistingValue(event) {
    const existingValue = event.currentTarget.innerText;
    this.hiddenTechniqueValueTarget.innerHTML = `<p>${existingValue}</p>`;
    this.createNegativeUrge(existingValue);
  }

  createNegativeUrge(value) {
    this.createNegativeUrgeHTML(value);
    this.closeNewTechniqueModal();
    this.clearTechniqueForm();
    const forms = document.querySelectorAll('[name="negative_urge[][title]"]');
    if (forms.length >= 3) {
      this.removeNewUrgeOption();
    }
  }

  removeNewUrgeOption() {
    if (this.hasNewUrgeTarget) {
      const forms = document.querySelectorAll(
        '[name="negative_urge[][title]"]',
      );
      if (forms.length >= 3) {
        this.newUrgeTarget.classList.add("hidden");
      } else {
        this.newUrgeTarget.classList.remove("hidden");
      }
    }
  }

  removeNewTechniqueOption() {
    if (this.hasTechniqueCreationTarget) {
      const forms = document.querySelectorAll(
        '[name="coping_technique[][title]"]',
      );
      if (forms.length >= 3) {
        this.techniqueCreationTarget.classList.add("hidden");
      } else {
        this.techniqueCreationTarget.classList.remove("hidden");
      }
    }
  }
}
