import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["query", "modal"];

  static values = { modalType: String };

  connect() {
    this.queryTarget.value = "";
  }

  filter() {
    const query = this.queryTarget.value.toLowerCase();
    const items = this.modalTarget.querySelectorAll("[data-emotion]");

    items.forEach((item) => {
      const emotion = item.dataset.emotion.toLowerCase();
      item.style.display = emotion.includes(query) ? "" : "none";
    });
  }
}
