import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "email",
    "password",
    "passwordRepeat",
    "agb",
    "submitButton",
    "passwordHint",
  ];

  static values = { login: String };

  connect() {
    if (this.loginValue !== "login") {
      this.emailTarget.addEventListener("input", this.checkFormFieldsRegister);
      this.passwordTarget.addEventListener(
        "input",
        this.checkFormFieldsRegister,
      );
      this.passwordRepeatTarget.addEventListener(
        "input",
        this.checkFormFieldsRegister,
      );
      this.agbTarget.addEventListener("input", this.checkFormFieldsRegister);
    } else {
      this.emailTarget.addEventListener("input", this.checkFormFieldsLogin);
      this.passwordTarget.addEventListener("input", this.checkFormFieldsLogin);
      this.passwordTarget.addEventListener("input", this.showPasswordHint);
    }
  }

  checkFormFieldsLogin = () => {
    const emailValue = this.emailTarget.value.trim();
    const passwordValue = this.passwordTarget.value.trim();

    this.toggleButton(emailValue && passwordValue);
  };

  checkFormFieldsRegister = () => {
    const emailValue = this.emailTarget.value.trim();
    const passwordValue = this.passwordTarget.value.trim();
    const passwordRepeatValue = this.passwordRepeatTarget.value.trim();
    const agbValue = this.agbTarget.checked;

    this.togglePasswordHint(
      passwordValue.length > 0 && !this.isValidPassword(passwordValue),
    );
    this.togglePasswordRepeatValidity(passwordValue === passwordRepeatValue);

    this.toggleButton(
      emailValue &&
        passwordValue &&
        passwordRepeatValue &&
        agbValue &&
        this.isValidPassword(passwordValue) &&
        passwordValue === passwordRepeatValue,
    );
  };

  showPasswordHint = () => {
    const passwordValue = this.passwordTarget.value.trim();
    this.togglePasswordHint(
      passwordValue.length > 0 && !this.isValidPassword(passwordValue),
    );
  };

  togglePasswordHint = (show) => {
    this.passwordHintTarget.style.display = show ? "block" : "none";
  };

  togglePasswordRepeatValidity = (isValid) => {
    if (isValid) {
      this.passwordRepeatTarget.classList.remove("is-invalid");
    } else {
      this.passwordRepeatTarget.classList.add("is-invalid");
    }
  };

  toggleButton = (enable) => {
    if (enable) {
      this.submitButtonTarget.classList.remove("disabled");
    } else {
      this.submitButtonTarget.classList.add("disabled");
    }
  };

  isValidPassword(password) {
    const minLength = 6;
    const hasSpecialChar = /(?=.*[0-9])(?=.*[!@#$%^&*])/.test(password);

    return password.length >= minLength && hasSpecialChar;
  }
}
