// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "links",
    "template",
    "prescriptionList",
    "prescriptionForm",
    "form",
    "submitButton",
    "url",
    "intake",
    "hiddenFields",
    "prescriptionId",
    "prescriptionTitle",
    "prescriptionDosis",
    "prescriptionUnit",
    "prescriptionInfo",
    "intakeForm",
    "deleteItem",
  ];

  connect() {
    const prescriptionIds = [];
  }

  add_association(event) {
    event.preventDefault();
    this.hasformTarget;
    if (this.hasformTarget) {
      this.formTarget.classList.remove("d-none");
    } else {
      let content = this.templateTarget.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime(),
      );
      this.linksTarget.insertAdjacentHTML("beforebegin", content);
    }
  }

  add_prescription(event) {
    event.preventDefault();
    const url = this.formTarget.action;
    fetch(url, {
      method: "POST",
      headers: { Accept: "text/plain" },
      body: new FormData(this.formTarget),
    })
      .then((response) => response.text())
      .then((data) => {
        this.prescriptionListTarget.insertAdjacentHTML("beforeend", data);
        this.formTarget.reset();
        this.submitButtonTarget.disabled = false;
        this.formTarget.classList.add("d-none");
        this.hasformTarget = true;
      });
  }

  close(event) {
    event.preventDefault();
    const deleteId = event.target.closest("li").dataset.deleteitem;
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/prescriptions/${deleteId}`;
    fetch(url, { method: "DELETE" });
    event.target.closest("div label").remove();
  }

  addIntake(event) {
    event.preventDefault();
    const prescriptionList = this.prescriptionIdTargets;
    let prescriptionIds = [];
    if (prescriptionList.length > 0) {
      prescriptionList.forEach((p) => {
        prescriptionIds.push(p.value);
      });
    }
    const prescriptionId = event.target.dataset.prescription;
    const prescriptionTitle = event.target.dataset.prescriptiontitle;
    const prescriptionDosis = event.target.dataset.prescriptiondosis;
    const prescriptionUnit = event.target.dataset.prescriptionunit;
    const prescriptionInfo = event.target.dataset.prescriptioninfo;

    if (prescriptionIds.includes(prescriptionId)) {
      this.prescriptionIdTargets.forEach((t) => {
        if (t.value === prescriptionId) {
          t.closest("div").remove();
        }
      });
    } else {
      let content = this.intakeTarget.innerHTML.replace(
        /NEW_RECORD/g,
        new Date().getTime(),
      );
      this.hiddenFieldsTarget.insertAdjacentHTML("beforebegin", content);
      const prescription = this.prescriptionIdTargets;
      const intakeTitle = this.prescriptionTitleTargets;
      const intakeDosis = this.prescriptionDosisTargets;
      const intakeUnit = this.prescriptionUnitTargets;
      const intakeInfo = this.prescriptionInfoTargets;
      const index = prescription.length - 1;

      prescription[index].value = prescriptionId;
      prescription[index].innerText = prescriptionId;
      intakeTitle[index].value = prescriptionTitle;
      intakeTitle[index].innerText = prescriptionTitle;
      intakeDosis[index].value = prescriptionDosis;
      intakeDosis[index].innerText = prescriptionDosis;
      intakeUnit[index].value = prescriptionUnit;
      intakeUnit[index].innerText = prescriptionUnit;
      intakeInfo[index].value = prescriptionInfo;
      intakeInfo[index].innerText = prescriptionInfo;
    }
  }
}
