import { Controller } from "@hotwired/stimulus";
import Shepherd from "shepherd.js";
// import 'shepherd.js/dist/css/shepherd.css';
export default class extends Controller {
  // static targets = ['output'];
  static values = {
    department: String,
  };

  connect() {
    this.#buildTour();
  }

  #buildTour() {
    const department = this.departmentValue;
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: true,
      },
    });
    tour.addStep({
      id: "first-step",
      text: `
        <strong id="test">Willkommen bei minime!</strong>
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        <p><br>${
          department === "coaching"
            ? "minime ist ein persönliches Coaching-Tagebuch für deine Klient:innen. "
            : ""
        }In dieser Übersicht werden alle ${
          department === "coaching" ? "Klient:innen" : "Patient:innen"
        } angezeigt die du zur App eingeladen hast.<br><br>Neue Einträge deiner ${
          department === "coaching" ? "Klient:innen" : "Patient:innen"
        } werden mit einem roten Punkt angezeigt.</p>
        `,
      classes: "example-step-extra-class",
      buttons: [
        {
          text: '<strong class="intro-skip" id="intro-skip">Intro überspringen</strong>',
          action: () => {
            if (
              [...document.querySelectorAll("#dont-show-intro-again")].at(-1)
                .checked == true
            ) {
              tour.cancel();
              window.location = "/disable_intro";
            } else {
              tour.cancel();
              window.location = "/disable_intro";
            }
          },
          classes: "custom-intro-skip-class",
        },
        {
          text: "<strong>Weiter</strong>",
          action: () => {
            if (
              document.querySelector("#dont-show-intro-again").checked == true
            ) {
              tour.cancel();
              window.location = "/disable_intro";
            } else {
              tour.next();
            }
          },
        },
      ],
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const footer = currentStepElement.querySelector(".shepherd-footer");
          const progress = document.createElement("p");
          progress.className = "shepherd-progress";
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${
            tour.steps.length
          }`;
          text.insertAdjacentElement("afterbegin", progress);

          // Create an empty div with a class
          const cancelDiv = document.createElement("div");
          cancelDiv.classList.add("cancel-div");
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>';
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector(".custom-intro-skip-class"),
          );
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement("afterbegin", cancelDiv);
        },
      },
    });
    tour.addStep({
      id: "second-step",
      arrow: false,
      text:
        department === "psychotherapie"
          ? `
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        <p>Hier kannst du deine Patient:innen ganz einfach und anonym per QR-Code oder Email zur gemeinsamen App einladen.<br><br>Deine Patient:innen erhalten dann einen Einladungs-Link per E-Mail.</p>`
          : `<img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        <p>Hier kannst du deine Klient:innen ganz einfach und anonym per QR-Code oder per Email zur gemeinsamen App einladen.<br><br>Deine Klient:innen erhalten dann einen Einladungs-Link per E-Mail.</p>`,
      buttons: [
        {
          text: '<strong id="test"></strong><strong>Weiter</strong>',
          action: () => {
            if (
              [...document.querySelectorAll("#dont-show-intro-again")].at(-1)
                .checked == true
            ) {
              tour.cancel();
              window.location = "/disable_intro";
            } else {
              tour.next();
            }
          },
        },
      ],
      attachTo: {
        element: ".intro-div",
        on: "bottom",
      },
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const footer = currentStepElement.querySelector(".shepherd-footer");
          const progress = document.createElement("p");
          progress.className = "shepherd-progress";
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${
            tour.steps.length
          }`;
          text.insertAdjacentElement("afterbegin", progress);

          // Create an empty div with a class
          const cancelDiv = document.createElement("div");
          cancelDiv.classList.add("cancel-div");
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>';
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector(".custom-intro-skip-class"),
          );
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement("afterbegin", cancelDiv);
        },
      },
    });
    tour.addStep({
      id: "third-step",
      text: `
        <div class="d-flex align-items-center">
        <img src="/assets/favicons/icon192.png" style="width: 40px; height: 40px; float:center;"></img>
        <strong class="ps-3">Bevor du dich weiter umguckst, kannst du minime auch als App auf deinem Smartphone hinzufügen!</strong>
        </div>
        <strong><br>1. Android Smartphones:</strong>
        <p>klicke im Browser auf die drei Punkte</p>
        <img src="/assets/icons/url.svg" style="width: 100%; float:center;"></img>
        <p>wähle dann "zum Startbildschirm hinzufügen" aus, fertig!</p>
        <strong><br>2. iPhones(iOS):</strong>
        <p>klicke im Browser auf das "teilen"-Icon</p>
        <img src="/assets/icons/ios-url.svg" style="width: 100%; float:center;"></img>
        <p>Wähle dann “erweitert” bzw. ziehe das Menü nach oben und wähle "zum Home-/Startbildschirm hinzufügen” aus, fertig!</p>
        `,
      classes: "example-step-extra-class",
      buttons: [
        {
          text: "<strong>Weiter</strong>",
          action: () => {
            if (
              [...document.querySelectorAll("#dont-show-intro-again")].at(-1)
                .checked == true
            ) {
              tour.cancel();
              window.location = "/disable_intro";
            } else {
              tour.next();
            }
          },
        },
      ],
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const footer = currentStepElement.querySelector(".shepherd-footer");
          const progress = document.createElement("p");
          progress.className = "shepherd-progress";
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${
            tour.steps.length
          }`;
          text.insertAdjacentElement("afterbegin", progress);

          // Create an empty div with a class
          const cancelDiv = document.createElement("div");
          cancelDiv.classList.add("cancel-div");
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>';
          // Move the 'Info ueberspringen' into the cancelDiv
          cancelDiv.appendChild(
            document.querySelector(".custom-intro-skip-class"),
          );
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement("afterbegin", cancelDiv);
        },
      },
    });
    tour.addStep({
      id: "fourth-step",
      arrow: false,
      text:
        department === "psychotherapie"
          ? `
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        <p>Durch das Klicken der Karte, gelangst du direkt in die jeweilige Verlaufsansicht deines:r Patient:in.<br><br>Alle Einträge werden dort chronologisch, in Echtzeit und für beide Seiten identisch angezeigt.</p>`
          : `
        <img src="/assets/icons/therapist.svg" style="width: 50px; float:right;"></img>
        <p>Durch das Klicken der Karte, gelangst du direkt in die jeweilige Verlaufsansicht deines:r Klient:in.<br><br>Alle Einträge werden dort chronologisch, in Echtzeit und für beide Seiten identisch angezeigt.</p>`,
      buttons: [
        {
          text: "",
          action: tour.cancel,
        },
        {
          text: "<strong>Fertig!</strong>",
          action: () => {
            if (
              [...document.querySelectorAll("#dont-show-intro-again")].at(-1)
                .checked == true
            ) {
              tour.cancel();
              window.location = "/disable_intro";
            } else {
              tour.complete();
            }
          },
        },
      ],
      attachTo: {
        element: ".patient-card",
        on: "bottom",
      },
      scrollTo: false,
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 15] } }],
      },
      modalOverlayOpeningRadius: 20,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const text = currentStepElement.querySelector(".shepherd-text");
          const footer = currentStepElement.querySelector(".shepherd-footer");
          const progress = document.createElement("p");
          progress.className = "shepherd-progress";
          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${
            tour.steps.length
          }`;
          text.insertAdjacentElement("afterbegin", progress);

          // Create an empty div with a class
          const cancelDiv = document.createElement("div");
          cancelDiv.classList.add("cancel-div");
          // Insert the checkbox to skip the intro
          cancelDiv.innerHTML =
            '<div class="d-flex align-items-center justify-content-start"><input type="checkbox" id="dont-show-intro-again"> <strong class="intro-skip ps-2">Nicht mehr anzeigen</strong><p></div>';
          // Move the 'Info ueberspringen' into the cancelDiv
          // cancelDiv.appendChild(document.querySelector('#intro-skip'))
          // Insert the cancel div into the DOM
          footer.insertAdjacentElement("afterbegin", cancelDiv);
        },
      },
    });
    tour.start();
  }
}
