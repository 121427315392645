// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "links",
    "template",
    "modal",
    "searchQuery",
    "addButton",
    "answerEvaluation",
  ];
  static values = {
    emotions: Array,
  };

  connect() {
    this.checkCards();
    this.emotionsValue = [
      "Angst",
      "Anspannung",
      "Eifersucht",
      "Einsamkeit",
      "Ekel",
      "Erleichterung",
      "Freude",
      "Liebe/Verliebtheit",
      "Lust",
      "Niedergeschlagenheit",
      "Ohnmacht",
      "Scham",
      "Schuld",
      "Stolz",
      "Traurigkeit",
      "Wut/Ärger",
      "Zufriedenheit",
      "Zuneigung",
    ];
  }

  openModal() {
    this.modalTarget.classList.remove("hidden");
    let emotionsAll = document.querySelectorAll("[data-emotion]");
    emotionsAll.forEach((elem) => {
      elem.style.display = "";
    });
    const url = window.location.pathname;
    this.disableAlreadySelectedIcons(emotionsAll);
  }

  closeModal(event) {
    if (this.hasSearchQueryTarget) {
      this.searchQueryTarget.value = "";
    }
    this.modalTarget.classList.add("hidden");
  }

  add_association(event) {
    event.preventDefault();
    // Safety check to make sure the function runs only on certains pages
    // as this code messes with the nested forms and I wasn't sure if they get
    // used somewhere else too
    let emotionName = "";
    if (event.currentTarget.dataset.emotion) {
      emotionName = event.currentTarget.dataset.emotion;
    } else {
      if (this.hasSearchQueryTarget) {
        emotionName = this.searchQueryTarget.value;
      }
    }

    let content = this.templateTarget.innerHTML
      .replace(/NEW_RECORD/g, new Date().getTime())
      .replace(/DEFAULT_NAME/g, emotionName)
      .replace(/DEFAULT_INTENSITY/g, 50);

    // Check if emotionName is in the EMOTIONS array
    if (this.emotionsValue.includes(emotionName)) {
      // Replace DEFAULT_IMAGE with the lowercase, sanitized emotionName
      content = content.replace(
        /DEFAULT_IMAGE/g,
        emotionName.toLowerCase().replace("/", ""),
      );
    } else {
      // Replace DEFAULT_IMAGE with "DEFAULT_IMAGE.svg"
      content = content.replace(/DEFAULT_IMAGE/g, "DEFAULT_IMAGE");
    }

    if (emotionName.includes("Skill")) {
      content = content.replace(
        /<div class="mb-3 string optional analysis_evaluations_answer">.*?<\/div>/s,
        this.skillAnswer(),
      );
    }
    this.linksTarget.insertAdjacentHTML("beforebegin", content);

    let ctaText = "Hinzufügen";
    const path = window.location.pathname;
    if (path.includes("feeling")) {
      ctaText = "Weiteres Gefühl";
    } else if (path.includes("susceptibility")) {
      ctaText = "Weitere Anfälligkeit";
    } else if (path.includes("evaluation")) {
      ctaText = "Weitere Frage";
    } else if (path.includes("pain")) {
      ctaText = "Weiteres Körperteil";
    }

    const button = `<div data-nested-form-target="links" class="rounded box-shadow p-3 bg-white w-100 justify-content-center" data-action="click->nested-form#openModal">
                  <div class="d-flex justify-content-center w-100">
                    <img src="/assets/icons/plus.svg" alt="" class="px-2">
                    <p>${ctaText}</p>
                  </div>
              </div>`;
    this.linksTarget.insertAdjacentHTML("afterend", button);
    this.linksTarget.remove();

    if (this.hasSearchQueryTarget) {
      this.searchQueryTarget.value = "";
    }
    this.closeModal();
    this.checkCards();
  }

  skillAnswer() {
    return `
            <div class="d-flex justify-content-center mt-3 w-100">
            <div class="w-100">
              <input readOnly type="text" placeholder="Suchen oder neu erstellen" data-skills-inputs-target='skillInput' class="w-100 textfield-input" data-action="click->skills-inputs#openNewSkillsModal">
            </div>
              <div data-skills-inputs-target="hiddenSkillInputFields">
              </div>
            </div>`;
  }

  checkCards() {
    let emotionCards = document.querySelectorAll("[data-emotion-name]").length;
    if (emotionCards >= 5) {
      this.linksTarget.classList.remove("d-flex");
      this.linksTarget.classList.add("d-none");
    } else {
      this.linksTarget.classList.add("d-flex");
      this.linksTarget.classList.remove("d-none");
    }
  }

  disableAlreadySelectedIcons(emotions) {
    // Vanilla JS code to remove the latest selected emotion from the list of options
    // let latestSelectedItems = []
    // Convert NodeList to Array with spread operator
    // Map over all elements and get rid of the default text ones
    let latestSelectedItems = [];
    document.querySelectorAll("[data-emotion-name]").forEach((elem) => {
      latestSelectedItems.push(elem.textContent.trim());
    });

    emotions.forEach((elem) => {
      if (latestSelectedItems.includes(elem.dataset.emotion)) {
        elem.remove();
      }
    });
  }
}
