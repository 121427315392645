import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["filter"]

  connect() {
    this.updateFilters();
  }

  updateFilters() {
    const ACTIVE_FILTER_CLASS = "active-filter";
    const PASSIVE_FILTER_CLASS = "passive-filter";
    const urlParams = new URLSearchParams(window.location.search);
    const activeFilters = urlParams.get('filters') ? urlParams.get('filters').split(',') : [];
    const passiveFilters = urlParams.get('passive') ? urlParams.get('passive').split(',') : [];

    this.filterTargets.forEach((filter) => {
      if (activeFilters.includes(filter.dataset.model)) {
        filter.classList.add(ACTIVE_FILTER_CLASS);
        filter.classList.remove(PASSIVE_FILTER_CLASS);
      } else {
        filter.classList.remove(ACTIVE_FILTER_CLASS);
        filter.classList.add(PASSIVE_FILTER_CLASS);
      }
    });
  }

  toggleFilter(event) {
    const modelName = event.currentTarget.dataset.model;
    const emotionName = event.currentTarget.dataset.emotion;
    const bodypartName = event.currentTarget.dataset.body; // Consider both bodypartName and bodypart
    const filterName = emotionName || bodypartName || modelName; // Prioritize emotionName, then bodypartName/bodypart, then modelName
    let filters = new URLSearchParams(window.location.search).get('filters');
    let passive = new URLSearchParams(window.location.search).get('passive');
    let activeFilters = filters ? filters.split(',') : [];
    let passiveFilters = passive ? passive.split(',') : [];

    // Determine the filter to toggle. Use emotionName if provided, else modelName.
    const filterToToggle = (emotionName && modelName === "Emotion") ? emotionName : filterName;

    // Check if the filter is currently active and toggle it
    if (activeFilters.includes(filterToToggle)) {
      activeFilters = activeFilters.filter(f => f !== filterToToggle);
    } else {
      activeFilters.push(filterToToggle);
    }

    // Check if the filter is currently passive and toggle it
    if (passiveFilters.includes(filterToToggle)) {
      passiveFilters = passiveFilters.filter(f => f !== filterToToggle);
    } else {
      passiveFilters.push(filterToToggle);
    }

    // Update the URL with the new set of active filters
    const newParams = new URLSearchParams(window.location.search);
    newParams.set('filters', activeFilters.join(','));
    newParams.set('passive', passiveFilters.join(','));
    window.history.pushState({}, '', `${window.location.pathname}?${newParams.toString()}`);

    // Refresh the page to reflect the new filter settings
    this.refreshEntries();
  }

  refreshEntries() {
    window.location.reload();
  }
}
