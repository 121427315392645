import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["username", "input", "btn"];

  connect() {}

  change() {
    if (this.inputTarget.value == this.usernameTarget.innerText) {
      // Enable the button if the have
      this.btnTarget.disabled = false;
    } else {
      // If the btn was enabled and they change any characters -> disbale it again
      this.btnTarget.disabled = true;
    }
  }
}
