import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["secondform"];
  onPostSuccess(event) {}

  update() {
    Rails.fire(this.secondformTarget, "submit");
  }
}
