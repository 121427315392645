import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="evaluation-page"
export default class extends Controller {
  static targets = [
    "cards",
    "card",
    "addCard",
    "title",
    "hiddenValue",
    "chev",
    "plus",
    "deleteButton",
    "modal",
    "questions",
    "question",
  ];

  connect() {
    this.questionChecker();
    this.changeText();
  }

  openModal(event) {
    const modal = this.modalTarget;
    modal.classList.remove("hidden");
  }

  closeModal(event) {
    const modal = this.modalTarget;
    modal.classList.add("hidden");
  }

  questionChecker(event) {
    const cards = this.cardTargets;
    const questions = this.questionTargets.map((question) =>
      question.innerText.trim().toLowerCase(),
    );
    cards.forEach((card) => {
      const cardText = card.querySelector("p").innerText.trim().toLowerCase();
      if (questions.includes(cardText)) {
        const questionIndex = questions.indexOf(cardText);
        if (questionIndex !== -1) {
          this.questionTargets[questionIndex].parentElement.remove();
          questions.splice(questionIndex, 1);
        }
      }
    });
  }

  addSkillSection(event) {
    const cards = this.cardsTarget;
    const html = event.target.parentElement.querySelector("p").innerText;

    const htmlStructure = `
      <div class="py-3" >
        <div data-evaluation-page-target="card" style="margin: 0 !important;" class="input-link-to box-shadow" data-action="click->modal-input-select#openModal">
            <p>${html}</p>
            <div data-evaluation-pagetarget="deleteButton" data-action="click->evaluation-page#deleteCard">X</div>
            </div>
            <div class="p-2 mt-3">
              <p>Skills</p>
            </div>
            <div class="d-flex justify-content-center mt-3" style="border-radius: 10px !important;" data-action="click->skills-inputs#openNewSkillsModal">
              <input readOnly type="text" class="coping-title new w-100" placeholder="Suchen oder neu erstellen" data-skills-inputs-target='skillInput'>
              <input type="hidden" name="evaluation[id]" value="">
            </div>
            <div data-skills-inputs-target="hiddenSkillInputFields">
            </div>
        </div>
      </div>`;

    cards.insertAdjacentHTML("beforeend", htmlStructure);
    event.target.parentElement.remove();
    this.closeModal();
    this.changeText();
  }

  addCard(event) {
    let placeholder = "";
    let name = "";

    const cards = this.cardsTarget;
    const html = event.target.parentElement.querySelector("p").innerText;

    if (
      event.target.innerText ==
      "An welcher Stelle hättest du etwas anders machen können?"
    ) {
      placeholder = "Verbesserungsfähig";
      name = "self_reflection_answer";
    } else if (
      event.target.innerText ==
      "Wie kannst du deine Anfälligkeit für überwältigende Gefühle und problematisches Verhalten in Zukunft verringern?"
    ) {
      placeholder = "Stärkungspotenzial";
      name = "susceptibility_answer";
    } else if (
      event.target.innerText ==
      "Wie kannst du das, was passiert ist, wiedergutmachen?"
    ) {
      placeholder = "Wiedergutmachung";
      name = "self_correction_answer";
    } else if (
      event.target.innerText ==
      "An welcher Stelle bist du mit deinem Verhalten zufrieden?"
    ) {
      placeholder = "Zufriedenheit";
      name = "pleased_answer";
    } else if (event.target.innerText == "Was ist deine Erkenntnis?") {
      placeholder = "Erkenntnis";
      name = "understanding_answer";
    }

    const htmlStructure = `
      <div class="py-3">
        <div data-evaluation-page-target="card" style="margin: 0 !important;" class="input-link-to box-shadow" data-action="click->modal-input-select#openModal">
            <p>${html}</p>
            <div data-evaluation-pagetarget="deleteButton" data-action="click->evaluation-page#deleteCard">X</div>
            </div>
            <div class="d-flex justify-content-center mt-3 ">
              <input type="text" placeholder="${placeholder}" name="evaluation[${name}]" class="input-field">
            </div>
        </div>
      </div>`;

    cards.insertAdjacentHTML("beforeend", htmlStructure);
    event.target.parentElement.remove();
    this.closeModal();
    this.changeText();
  }

  deleteCard(event) {
    event.preventDefault();
    event.target.parentElement.parentElement.parentElement.remove();

    let text = event.target.parentElement.querySelector("p").innerText;

    if (text == "X") {
      text =
        event.target.parentElement.parentElement.querySelector("p").innerText;
    }

    const html = `
        <div>
          <div class="coping-title new" data-action="click->evaluation-page#addCard">
            <p>${text}</p>
          </div>
        </div>
    `;
    this.questionsTarget.insertAdjacentHTML("afterbegin", html);
    this.changeText();
  }

  changeText(event) {
    const cards = this.cardTargets;
    const cardAmount = cards.length;
    const plusSrc = this.plusTarget.getAttribute("src");
    const chevSrc = this.chevTarget.getAttribute("src");
    if (cardAmount <= 0) {
      this.addCardTarget.innerHTML = `<p>Frage</p>
        <img src="${chevSrc}" alt="">`;
    } else {
      this.addCardTarget.innerHTML = `<div class="d-flex">
          <img src="${plusSrc}" alt="" class="px-2">
          <p>Weitere Frage hinzufügen</p>
          </div>`;
    }
  }

  removeQuestion(event) {
    event.preventDefault();
    this.changeText();
  }
}
