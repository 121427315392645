import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "item",
    "button",
    "itemNotes",
    "shortDescription",
    "longDescription",
  ];
  static values = { items: Number, expanded: Boolean };

  connect() {
    this.toggle();
    this.toggleButtonVisibility();
  }

  toggleButtonVisibility() {
    if (this.hasItemNotesTarget) {
      this.buttonTarget.style.display = "";
    } else {
      this.buttonTarget.style.display = "none"; // Hide the button
    }
  }

  toggle() {
    if (this.hasItemNotesTarget) {
      let item = this.itemNotesTarget;
      let shortDescription = this.hasShortDescriptionTarget
        ? this.shortDescriptionTarget
        : null;
      let longDescription = this.hasLongDescriptionTarget
        ? this.longDescriptionTarget
        : null;

      // Initialize visibility based on expandedValue
      if (this.expandedValue) {
        item.style.maxHeight = "";
        item.style.overflow = "";
        if (shortDescription) {
          shortDescription.style.display = "none";
        }
        if (longDescription) {
          longDescription.style.display = "block";
        }
      } else {
        item.style.maxHeight = "0";
        item.style.overflow = "hidden";
        if (shortDescription) {
          shortDescription.style.display = "block";
        }
        if (longDescription) {
          longDescription.style.display = "none";
        }
      }

      // Toggle states
      this.expandedValue = !this.expandedValue;
    }
  }
}
