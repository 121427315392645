import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="create"
export default class extends Controller {
  static targets = ["card", "form", "close", "submit", "items", "link"];
  static values = {};

  connect() {}

  create(event) {
    event.preventDefault();

    const modalElement = event.target.closest(".modal");

    // Grab the submit button from the modal
    const submitButton = modalElement.querySelector('input[type="submit"]');
    const url = event.target.action;
    fetch(url, {
      method: "POST",
      headers: { Accept: "text/plain" },
      body: new FormData(event.target),
    })
      .then((response) => response.text())
      .then((data) => {
        this.itemsTarget.insertAdjacentHTML("afterbegin", data);
        submitButton.disabled = false;
      });
    // Make sure to close the modal after the fetch is done
    modalElement.querySelector("button.btn-close ").click();
  }

  delete(event) {
    event.preventDefault();

    const url = this.linkTarget.href;
    fetch(url, {
      method: "DELETE",
    })
      .then((response) => response.text())
      .then((data) => {});
    event.target.closest(".card-item").remove();
  }
}
