import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["interval", "settings", "date", "form", "bell"];

  static values = {
    id: Number,
  };
  connect() {
    if (navigator.serviceWorker && window.PushManager && window.Notification) {
      this.bellTarget.classList.remove("d-none");
    }
  }

  intervalChanged() {
    if (this.intervalTarget.value == "Täglich") {
      this.settingsTarget.classList.remove("d-none");
      this.dateTarget.classList.add("d-none");
    } else {
      this.settingsTarget.classList.add("d-none");
      this.dateTarget.classList.remove("d-none");
    }
  }

  displayForm() {
    this.formTarget.classList.toggle("d-none");
  }

  setReminder(event) {
    event.preventDefault();
    // event.target is the form that triggers the event
    // use .closest to grab the modal element and extract the id
    const closestElement = event.target.closest(".border-reminder");
    event.preventDefault();
    const url = event.target.action;
    const form = new FormData(event.target);
    form.append("id", this.idValue);

    fetch(url, {
      method: "POST",
      headers: { Accept: "text/plain" },
      body: form,
    })
      .then((response) => response.text())
      .then((data) => {
        closestElement.outerHTML = data;

        // Make the checkmark green
        this.bellTarget.innerHTML =
          "<div class='d-flex align-items-center justify-content-start' data-action='click->reminder#displayForm' data-reminder-target='bell' id='bell'><img src='/assets/tasks/notification-green.svg'  class='menu-icon'><p class='small ps-1'>Erinnerung aktiviert</p></div>";
        if (window.location.search == "?weekly") {
          // make sure the task on the timeline also gets updated
          const timeline_task = document.querySelectorAll(
            `div[data-reminder-id-value="${this.idValue}"]`,
          )[0];
          timeline_task.querySelector("#bell").innerHTML =
            "<div class='d-flex align-items-center justify-content-start' data-action='click->reminder#displayForm' data-reminder-target='bell' id='bell'><img src='/assets/tasks/notification-green.svg'  class='menu-icon'><p class='small ps-1'>Erinnerung aktiviert</p></div>";
          const closestBorder = timeline_task.querySelector(".border-reminder");
          closestBorder.outerHTML = data;
        } else {
          // make sure the task on the weekly also gets updated
          const weekly_task = document.querySelectorAll(
            `div[data-reminder-id-value="${this.idValue}"]`,
          );
          if (weekly_task.length > 1) {
            weekly_task[1].querySelector("#bell").innerHTML =
              "<div class='d-flex align-items-center justify-content-start' data-action='click->reminder#displayForm' data-reminder-target='bell' id='bell'><img src='/assets/tasks/notification-green.svg'  class='menu-icon'><p class='small ps-1'>Erinnerung aktiviert</p></div>";
            const closestBorderWeekly =
              weekly_task[1].querySelector(".border-reminder");
            closestBorderWeekly.outerHTML = data;
          }
        }
      });
  }
}
