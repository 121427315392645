import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["output", "input"];

  static values = {
    unit: String,
    model: String,
  };

  connect() {
    this.inputTarget.addEventListener("input", (event) => {
      const sliderValue = Math.floor(this.inputTarget.value);
      this.outputTarget.innerHTML = `${sliderValue}${this.unitValue}`;
    });
  }

  setinput() {
    const sliderValue = Math.floor(this.inputTarget.value);
    this.outputTarget.innerHTML = `${sliderValue}${this.unitValue}`;
  }

  change() {
    const sliderValue = Math.floor(this.inputTarget.value);

    this.outputTarget.innerHTML = `${sliderValue}${this.unitValue}`;
  }
}
