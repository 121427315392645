// import { Controller } from "@hotwired/stimulus"
import Dropdown from "stimulus-dropdown";

export default class extends Dropdown {
  static targets = [
    "button",
    "hidden",
    "selected",
    "customEmotion",
    "customBodypart",
    "slider",
    "sliderDiv",
    "newFormButton",
    "customUnit",
  ];

  connect() {
    super.connect();
    this.disableFirstNewButton();
  }

  toggle(event) {
    super.toggle();
  }

  // the change and the select function should be combined into 1 but I will do it some other time hehe
  change(event) {
    if (this.sliderDivTarget) {
      this.sliderDivTarget.style.display = "block";
      this.sliderTarget.disabled = false;
    }
  }

  selectQuestion(event) {
    event.preventDefault();
    const text = event.currentTarget.innerText;
    this.hiddenTarget.value = text;
    this.buttonTarget.innerText = text;
  }

  selectProtocol(event) {
    event.preventDefault();
    const text = event.currentTarget.innerText.trim();
    this.hiddenTarget.value = text;
    this.buttonTarget.innerHTML = `<p>${text}</p>`;
  }

  select(event) {
    event.preventDefault();
    this.sliderDivTarget.style.display = "block";
    const text = event.currentTarget.innerText;
    this.hiddenTarget.value = text;
    this.buttonTarget.innerText = text;
    this.customEmotionTarget.value = text;
  }

  selectUnit(event) {
    event.preventDefault();
    const text = event.currentTarget.innerText;
    this.hiddenTarget.value = text;
    this.buttonTarget.innerText = text;
    this.customUnitTarget.value = text;
  }

  selectBodypart(event) {
    event.preventDefault();
    const text = event.currentTarget.querySelector("p").innerText;
    this.hiddenTarget.value = text;
    this.buttonTarget.innerText = text;
    this.customBodypartTarget.value = text;
    const selectedSvg = event.currentTarget.querySelector("svg").outerHTML;
    this.selectedTarget.innerHTML = selectedSvg;
    this.selectedTarget.classList.remove("d-none");
    this.customBodypartTarget.value = "";
  }

  send(event) {
    // event.preventDefault()
    this.hiddenTarget.value = this.customEmotionTarget.value;
    this.buttonTarget.innerText = this.customEmotionTarget.value;
    this.toggle(event);
  }

  sendBodypart(event) {
    this.hiddenTarget.value = this.customBodypartTarget.value;
    this.buttonTarget.innerText = this.customBodypartTarget.value;
    this.selectedTarget.classList.add("d-none");
    this.toggle(event);
  }

  sendUnit(event) {
    this.hiddenTarget.value = this.customUnitTarget.value;
    this.buttonTarget.innerText = this.customUnitTarget.value;
    // this.selectedTarget.classList.add('d-none')
    this.toggle(event);
  }

  close(event) {
    // check to see if the button that was click contains an id
    const id = event.currentTarget.dataset.id;

    if (id) {
      // if it does exist, target the hidden input from simple_fields_field and remove it
      document.querySelector(`input[value="${id}"][type="hidden"]`).remove();
    }
    const numberOfbuttons = document.querySelectorAll(
      "#feelings-close-button",
    ).length;
    const closeButton = event.target;
    this.element.remove();
    if (closeButton.dataset.delete) {
      this.rerenderEmptyFormCheck();
    }

    const nestedFormController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector('[data-controller="nested-form"]'),
        "nested-form",
      );
    if (nestedFormController) {
      nestedFormController.checkCards();
    }
  }

  disableFirstNewButton() {
    const newButtons = document.querySelectorAll("div#feelings-close-button");
    const ajaxButtons = document.querySelectorAll(
      "a#feelings-close-button",
    ).length;
    if (newButtons[0] && ajaxButtons === 0) {
      newButtons[0].style.display = "none";
    }
  }

  rerenderEmptyFormCheck() {
    const ajaxButtons = document.querySelectorAll(
      "a#feelings-close-button",
    ).length;
    if (ajaxButtons === 0) {
      document.querySelector('a[data-dropdown-target="newFormButton"]').click();
    }
  }
}
