// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["email", "confirm"];

  connect() {}

  change() {
    this.confirmTarget.innerText = this.emailTarget.value;
    // Check if the therapist has written Delete patient
    // if (this.inputTarget.value == this.messageValue) {
    //   // Enable the button if the have
    //   this.buttonTarget.disabled = false
    // } else {
    //   // If the button was enabled and they change any characters -> disbale it again
    //   this.buttonTarget.disabled = true
    // }
  }
}
