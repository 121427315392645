import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "field"];

  static values = { title: String };

  connect() {
    if (this.titleValue !== "") {
      this.optionTargets.forEach((el) => {
        el.lastElementChild.classList.contains(this.titleValue)
          ? el.lastElementChild.classList.add("active-mood")
          : "";
      });
    }
  }

  selectRadioOption(event) {
    event.preventDefault();
    this.optionTargets.forEach((el) => {
      el.lastElementChild.classList.remove("active-mood");
    });
    event.target.classList.toggle("active-mood");

    this.fieldTarget.value = event.target.dataset.value;
  }
}
