import { Controller } from "@hotwired/stimulus";
import { Popover } from "bootstrap";

export default class extends Controller {
  static values = {
    contentId: String,
  };

  popovers = []; // Track all popovers

  connect() {
    this.initializePopovers();
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this));
  }

  initializePopovers() {
    const popover = new Popover(this.element, {
      placement: "top",
      customClass: "bs-gray",
      content: () => {
        const contentElement = document.getElementById(this.contentIdValue);
        return contentElement ? contentElement.innerHTML : "";
      },
      html: true,
    });

    this.popovers.push(popover);

    this.element.addEventListener("shown.bs.popover", () => {
      this.closeOtherPopovers(popover);
    });
  }

  closeOtherPopovers(currentPopover) {
    this.popovers.forEach((p) => {
      if (p !== currentPopover) {
        p.hide();
      }
    });
  }

  handleOutsideClick(event) {
    let targetElement = event.target; // Clicked element
    let insidePopoverOrTrigger = this.popovers.some((popover) => {
      const popoverElement = popover._element;
      const triggerElement = popover._element;
      return (
        popoverElement.contains(targetElement) ||
        triggerElement === targetElement
      );
    });

    if (!insidePopoverOrTrigger) {
      this.popovers.forEach((popover) => popover.hide());
    }
  }
}
