import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="service-worker"
export default class extends Controller {
  connect() {
    // Initialization code if needed
  }

  async toggleSubscription(event) {
    if (event.target.checked) {
      // User wants to subscribe
      try {
        const registration = await this.registerServiceWorker();
        await this.askPermission();
        await this.subscribeUserToPush(registration);
      } catch (error) {
        console.error("An error occurred during subscription:", error);
      }
    } else {
      // User wants to unsubscribe
      try {
        await this.unsubscribeUserFromPush();
      } catch (error) {
        console.error("An error occurred during unsubscription:", error);
      }
    }
  }

  async registerServiceWorker() {
    try {
      const registration = await navigator.serviceWorker.register(
        "/serviceworker.js",
        { scope: "./" }
      );
      return registration;
    } catch (error) {
      console.error("Service Worker registration failed:", error);
      throw error;
    }
  }

  async askPermission() {
    const permissionResult = await Notification.requestPermission();
    if (permissionResult !== "granted") {
      throw new Error("Notification permission not granted.");
    }
  }

  async subscribeUserToPush(registration) {
    const appKey = this.urlB64ToUint8Array(
      "BAEs7IN4ex43q2ApKjYVzyWD_q-bdhpC9BESPsGLxdEEVmpJf17_dAQMITMeGfx72IUBEK4CANw6aAiwNqYG8Fs"
    );
    try {
      let subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: appKey,
        };
        subscription = await registration.pushManager.subscribe(subscribeOptions);
      }
      // Wrap the subscription data under the 'subscription' key
      const data = { subscription: subscription.toJSON() };
      await fetch("/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.getMetaValue("csrf-token"),
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Failed to subscribe the user:", error);
      throw error;
    }
  }

  async unsubscribeUserFromPush() {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
        // Wrap the subscription data under the 'subscription' key
        const data = { subscription: subscription.toJSON() };
        // Notify your server to remove the subscription
        await fetch("/unsubscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": this.getMetaValue("csrf-token"),
          },
          body: JSON.stringify(data),
        });
      }
    } catch (error) {
      console.error("Failed to unsubscribe the user:", error);
      throw error;
    }
  }

  getMetaValue(name) {
    const element = document.querySelector(`meta[name="${name}"]`);
    return element && element.getAttribute("content");
  }


  urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}
