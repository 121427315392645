import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'timeline',
    'weekly',
    'switch',
    'tswitch',
    'wswitch',
    'previous',
    'next',
    'reset',
    'page',
    'filter',
    'minime',
  ]
  static values = {
    referrer: Boolean,
  }
  connect() {
    this.params = window.location.search
    this.switchTargets.forEach((s) => {
      s.classList.remove('active-switch')
    })
    if (
      this.params.includes('next') ||
      this.params.includes('previous') ||
      this.params.includes('weekly') ||
      this.referrerValue
    ) {
      this.timelineTarget.classList.add('d-none')
      this.weeklyTarget.classList.remove('d-none')
      this.wswitchTarget.classList.add('active-switch')
      this.resetTarget.href =
        this.resetTarget.href.split('?')[0] + '?reset&weekly'
      this.filterTarget.name = 'weekly'
      if (this.referrerValue) {
        window.history.pushState(
          null,
          null,
          window.location.pathname + '?weekly'
        )
      }
    } else {
      this.timelineTarget.classList.remove('d-none')
      this.weeklyTarget.classList.add('d-none')
      this.tswitchTarget.classList.add('active-switch')
      this.resetTarget.href =
        this.resetTarget.href.split('?')[0] + '?reset&timeline'
      this.filterTarget.name = 'timeline'
    }
  }

  switch(e) {
    this.switchTargets.forEach((s) => s.classList.remove('active-switch'))
    this.url = window.location.href.split('?')[0]

    if (e.currentTarget === this.wswitchTarget) {
      this.params = '?weekly'
      this.newUrl = this.url + this.params
      this.resetTarget.href =
        this.resetTarget.href.split('?')[0] + '?reset&weekly'
      this.filterTarget.name = 'weekly'
      window.history.pushState(null, null, this.newUrl) // or pushState
      e.currentTarget.classList.add('active-switch')
      this.timelineTarget.classList.add('d-none')
      this.weeklyTarget.classList.remove('d-none')
    } else if (e.currentTarget === this.tswitchTarget) {
      this.params = '?timeline'
      this.newUrl = this.url + this.params
      this.resetTarget.href =
        this.resetTarget.href.split('?')[0] + '?reset&timeline'
      this.filterTarget.name = 'timeline'
      window.history.pushState(null, null, this.newUrl) // or pushState

      e.currentTarget.classList.add('active-switch')
      this.timelineTarget.classList.remove('d-none')
      this.weeklyTarget.classList.add('d-none')
    } else if (
      this.params.includes('next') ||
      this.params.includes('previous')
    ) {
      this.weeklyTarget.classList.remove('d-none')
      this.timelineTarget.classList.add('d-none')
    }
  }
}
