import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["completedField", "markCompletedButton", "markNotCompletedButton", "intensityInput", "intensityOutput"]

  connect() {
    this.updateButtonVisibility()
  }

  setCompleted(event) {
    this.completedFieldTarget.value = 'true';
    this.intensityInputTarget.value = 100;
    this.intensityOutputTarget.textContent = '100%';
    this.updateButtonVisibility();
  }

  setNotCompleted(event) {
    this.completedFieldTarget.value = 'false';
    this.intensityInputTarget.value = 0;
    this.intensityOutputTarget.textContent = '0%';
    this.updateButtonVisibility();
  }

  updateButtonVisibility() {
    if (this.completedFieldTarget.value === 'true') {
      this.markCompletedButtonTarget.style.display = 'none';
      this.markNotCompletedButtonTarget.style.display = 'block';
    } else {
      this.markCompletedButtonTarget.style.display = 'block';
      this.markNotCompletedButtonTarget.style.display = 'none';
    }
  }
}
