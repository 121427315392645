import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["modal", "wiederholungModal"];

  connect() {}

  open() {
    this.modalTarget.classList.add("open");
    document.body.classList.add("modal-open");
  }

  close() {
    this.modalTarget.classList.remove("open");
    document.body.classList.remove("modal-open");
  }

  openWiederholung() {
    this.wiederholungModalTarget.classList.add("open");
  }

  closeWiederholung() {
    this.wiederholungModalTarget.classList.remove("open");
  }
}
