import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal-input-select"
export default class extends Controller {
  static targets = [
    "input",
    "modal",
    "card",
    "cards",
    "addCard",
    "hiddenValue",
    "chev",
    "plus",
    "deleteButton",
    "scale",
    "links",
    "template",
  ];

  connect() {
    this.checkCards();
  }

  initialize() {
    // Initialize cardAmount with 0 or get it from where you are setting it initially
    this.cardAmount = this.cardTargets.length; // or set it to your initial value
  }

  openModal() {
    this.modalTarget.classList.remove("hidden");
  }

  closeModal(event) {
    this.modalTarget.classList.add("hidden");
    this.hiddenValueTarget.value = "";
  }

  addCard(event) {
    event.preventDefault();
    this.hiddenValueTarget.value = event.currentTarget.innerText;
  }

  getTherapyIdFromEmoPath() {
    const matches = window.location.pathname.match(
      /edit_analysis_emotion\/(\d+)/,
    );
    return matches ? parseInt(matches[1]) : null;
  }

  getTherapyIdFromSusPath() {
    const matches = window.location.pathname.match(
      /edit_analysis_susceptibility\/(\d+)/,
    );
    return matches ? parseInt(matches[1]) : null;
  }

  isOnTherapySusceptibilitiesPath() {
    const therapyId = this.getTherapyIdFromSusPath();
    return (
      therapyId !== null &&
      window.location.pathname.includes(
        `/edit_analysis_susceptibility/${therapyId}`,
      )
    );
  }

  isOnTherapyEmotionsPath() {
    const therapyId = this.getTherapyIdFromEmoPath();
    return (
      therapyId !== null &&
      window.location.pathname.includes(`/edit_analysis_emotion/${therapyId}`)
    );
  }

  checkCards() {
    if (this.isOnTherapySusceptibilitiesPath()) {
      if (this.cardTargets.length == 1) {
        this.deleteButtonTarget.classList.add("d-none");
      } else {
        this.deleteButtonTargets.forEach((button) => {
          button.classList.remove("d-none");
        });
      }
      if (this.cardTargets.length >= 5) {
        this.addCardTarget.classList.add("d-none");
      } else {
        this.addCardTarget.classList.remove("d-none");
      }
    } else if (this.isOnTherapyEmotionsPath()) {
      if (this.cardTargets.length == 1) {
        this.deleteButtonTarget.classList.add("d-none");
      } else {
        this.deleteButtonTargets.forEach((button) => {
          button.classList.remove("d-none");
        });
      }
      if (this.cardTargets.length >= 3) {
        this.addCardTarget.classList.add("d-none");
      } else {
        this.addCardTarget.classList.remove("d-none");
      }
    } else {
      if (this.cardTargets.length >= 5) {
        this.addCardTarget.classList.add("d-none");
      } else {
        this.addCardTarget.classList.remove("d-none");
      }

      if (this.cardTargets.length == 0) {
        this.changeSusceptibilityCard();
      }
    }
  }

  saveCard() {
    if (this.isOnTherapySusceptibilitiesPath()) {
      const cards = this.cardsTarget;
      const html = `<div>
        <div data-modal-input-select-target="card" class="input-link-to box-shadow" data-action="click->modal-input-select#openModal">
          <p>${this.hiddenValueTarget.value}</p>
          <input type="hidden" value="${this.hiddenValueTarget.value}" name="susceptibility[][title]">
          <div data-modal-input-select-target="deleteButton" data-action="click->modal-input-select#deleteCard">x</div>
        </div>
      </div>
    `;
      cards.insertAdjacentHTML("beforebegin", html);
    } else {
      let emotionName = this.hiddenValueTarget.value;
      let content = this.templateTarget.innerHTML
        .replace(/NEW_RECORD/g, new Date().getTime())
        .replace(/DEFAULT_NAME/g, emotionName);
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = content;

      // Remove 'disabled' attributes from elements within the temporary container
      const disabledElements = tempContainer.querySelectorAll("[disabled]");
      disabledElements.forEach((element) => {
        element.removeAttribute("disabled");
      });
      this.linksTarget.insertAdjacentHTML(
        "beforebegin",
        tempContainer.innerHTML,
      );
      this.cardAmount++;
    }
    this.closeModal();
    this.checkCards();
  }

  addConsequence() {
    const cards = this.cardsTarget;
    const cardAmount = this.cardTargets.length;
    const html = `<div>
              <div style="position: relative;">
              <input type="text" name="consequence[${cardAmount}][name]" placeholder="Konsequenz"class="bogen-selection start w-100 px-3 word-break" style="min-height: 100px;" data-modal-input-select-target="card">
              <div data-modal-input-select-target="deleteButton" data-action="click->modal-input-select#deleteCard" class="position-absolute  delete-btn-position">
                <img src="/assets/icons/close-cross.svg" alt="" class='px-2'>
              </div>  
              </div>
              <div class="diary-checkbox-title" style="border-radius: 0 !important;">
              <p>Wie ordnest du diese Konzequenz ein?</p>
              </div>
              <div class="diary-checkbox-form" style="border-radius: 0 0 10px 10px" data-controller="diary-card" data-diary-card-target="form" style="border-radius: 0 !important;">
              <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" ><input class="diary-checkbox-inputs-yes-no" type="checkbox" name="consequence[${cardAmount}][rating]" data-diary-card-target="diaryCheckbox" value="1" ><span>Positiv</span></label></div>
              <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" ><input class="diary-checkbox-inputs-yes-no" type="checkbox" name="consequence[${cardAmount}][rating]" data-diary-card-target="diaryCheckbox" value="0" ><span>Negativ</span></label></div>
             </div>`;
    cards.insertAdjacentHTML("afterend", html);
    this.checkCards();
  }

  addImplication() {
    const cardAmount = this.cardAmount;
    const cards = this.cardsTarget;
    const html = `<div data-modal-input-select-target="card">
        <div style="position: relative;">
            <input type="text" name="implication[${cardAmount}][name]" placeholder="Folge" class="bogen-selection start w-100 px-3 word-break" style="min-height: 100px;">
            <div data-modal-input-select-target="deleteButton" data-action="click->modal-input-select#deleteCard" class="position-absolute  delete-btn-position">
              <img src="/assets/icons/close-cross.svg" alt="" class='px-2'>
            </div>    
        </div>
        <div class="diary-checkbox-title" style="border-radius: 0 !important;">
            <p>Wie ordnest du diese Folge ein?</p>
        </div>
    <div class="diary-checkbox-form" style="border-radius: 0 0 10px 10px" data-controller="diary-card" data-diary-card-target="form" style="border-radius: 0 !important;">
    <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" ><input class="diary-checkbox-inputs-yes-no" type="checkbox" name="implication[${cardAmount}][rating]" data-diary-card-target="diaryCheckbox" value="1" ><span>Positiv</span></label></div>
    <div class="diary-checkbox" ><label data-action="mouseup->diary-card#formCheckbox" ><input class="diary-checkbox-inputs-yes-no" type="checkbox" name="implication[${cardAmount}][rating]" data-diary-card-target="diaryCheckbox" value="0" ><span>Negativ</span></label></div>
    </div>
    </div>`;
    cards.insertAdjacentHTML("beforeend", html);
    this.cardAmount++;
    this.checkCards();
  }

  changeSusceptibilityCard() {
    if (this.isOnTherapySusceptibilitiesPath()) {
      const cards = this.cardTargets;
      const cardAmount = cards.length;
      const plusSrc = this.plusTarget.getAttribute("src");
      const chevSrc = this.chevTarget.getAttribute("src");
      if (cardAmount > 0) {
        this.addCardTarget.innerHTML = `<div class="d-flex">
        <img src="${plusSrc}" alt="" class="px-2">
        <p>Weitere Anfälligkeiten hinzufügen</p>
        </div>`;
      } else {
        this.addCardTarget.innerHTML = `<p>Anfälligkeit</p>
        <img src="${chevSrc}" alt="">`;
      }
    } else {
      const cards = this.cardTargets;
      const cardAmount = cards.length;
      const plusSrc = this.plusTarget.getAttribute("src");
      const chevSrc = this.chevTarget.getAttribute("src");
      if (cardAmount > 0) {
        this.addCardTarget.innerHTML = `<div class="d-flex">
        <img src="${plusSrc}" alt="" class="px-2">
        <p>Weiteres Gefühl hinzufügen</p>
        </div>`;
      } else {
        this.addCardTarget.innerHTML = `<p>Gefühl</p>
        <img src="${chevSrc}" alt="">`;
      }
    }
  }

  deleteCard(event) {
    event.preventDefault();
    event.currentTarget.parentElement.parentElement.remove();
    this.checkCards();
  }

  updateScaleValue(event) {
    event.currentTarget.nextElementSibling.innerHTML = `<p>${event.currentTarget.value}%</p>`;
  }
}
