import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  unfold() {
    const unfolds = document.querySelectorAll(".show-more-icon");
    unfolds.forEach((unfold) => {
      unfold.click();
    });
    window.print();
  }
}
