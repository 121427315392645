import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="diary-card-dropdown"
export default class extends Controller {
  static targets = [
    "dropdownContainer",
    "dropdownEli",
    "chevron",
    "editForm",
    "currentTechnique",
    "currentTechniqueValue",
    "blueBorderContainer",
    "blueBorderTitle",
  ];

  static values = { id: String };

  connect() {}

  dropdown() {
    this.dropdownContainerTarget.classList.toggle("hidden");
    this.chevronTarget.classList.toggle("chevron-flip");
  }

  dropdownNoChevron() {
    this.dropdownContainerTarget.classList.toggle("hidden");
  }

  dropdownElis() {
    this.dropdownContainerTarget.classList.toggle("hidden");
    this.dropdownEliTarget.classList.toggle("hidden");
  }

  openEditTechnique() {
    this.editFormTarget.classList.toggle("hidden");
    this.editFormTarget.classList.toggle("d-flex");
    this.currentTechniqueTarget.classList.toggle("hidden");
    this.dropdownContainerTarget.classList.toggle("hidden");
  }

  borderSwap() {
    this.blueBorderContainerTarget.classList.toggle("blue-border");
    this.blueBorderTitleTarget.classList.toggle("blue-border");
  }
  resetDropdown() {
    this.dropdownContainerTarget.classList.add("hidden");
    this.editFormTarget.classList.add("hidden");
    this.editFormTarget.classList.remove("d-flex");
    this.currentTechniqueTarget.classList.remove("hidden");
    this.dropdownEliTarget.classList.remove("hidden");
  }

  updateTechnique(event) {
    event.preventDefault();

    const newTitle = this.currentTechniqueValueTarget.value;
    const copingTechniqueId = parseInt(this.idValue, 10);
    const yourCSRFToken = document.querySelector('[name="csrf-token"]').content;

    fetch(`/coping_techniques/${copingTechniqueId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": `${yourCSRFToken}`, // Make sure to replace with the actual CSRF token
      },
      body: JSON.stringify({ title: newTitle }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error updating coping technique:", error);
      });
    this.currentTechniqueTarget.innerText = newTitle;
    this.resetDropdown();
  }

  updateUrge(event) {
    event.preventDefault();

    const newTitle = this.currentTechniqueValueTarget.value;
    const copingTechniqueId = parseInt(this.idValue, 10);
    const yourCSRFToken = document.querySelector('[name="csrf-token"]').content;

    fetch(`/negative_urges/${copingTechniqueId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": `${yourCSRFToken}`, // Make sure to replace with the actual CSRF token
      },
      body: JSON.stringify({ title: newTitle }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error updating coping technique:", error);
      });
    this.currentTechniqueTarget.innerText = newTitle;
    this.resetDropdown();
  }

  updateSus(event) {
    event.preventDefault();

    const newTitle = this.currentTechniqueValueTarget.value;
    const Id = parseInt(this.idValue, 10);
    const yourCSRFToken = document.querySelector('[name="csrf-token"]').content;

    fetch(`/susceptibilities/${Id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": `${yourCSRFToken}`, // Make sure to replace with the actual CSRF token
      },
      body: JSON.stringify({ title: newTitle }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error updating coping technique:", error);
      });
    this.currentTechniqueTarget.innerText = newTitle;
    this.resetDropdown();
  }
}
