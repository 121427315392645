import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comment-modal"
export default class extends Controller {
  static targets = ["list"];

  connect() {}

  listTargetConnected() {
    this.listTarget.scrollTop = this.listTarget.scrollHeight;
  }
}
