import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  removeBackdrop() {
    const body = document.querySelector("body");
    body.classList.add("no-modal-bg");
  }

  addBackdrop() {
    const body = document.querySelector("body");
    body.classList.remove("no-modal-bg");
  }
}
